<template>
  <div ref="containerRef" class="flex flex-col space-y-16">
    <div
      class="grid gap-16 md:flex md:flex-wrap"
      :style="allFieldsData.name.style"
    >
      <RevInputText
        v-if="fields.firstName"
        id="firstName"
        class="md:min-w-[20rem] md:flex-1"
        data-cs-mask
        :error="errors.firstName"
        :label="i18n(addressFieldsTranslations.firstName)"
        :model-value="firstName"
        name="firstName"
        :style="allFieldsData.firstName.style"
        :tabindex="allFieldsData.firstName.index"
        @input="
          emit('update:firstName', ($event.target as HTMLInputElement).value)
        "
      />

      <RevInputText
        v-if="fields.lastName"
        id="lastName"
        class="md:min-w-[20rem] md:flex-1"
        data-cs-mask
        :error="errors.lastName"
        :label="i18n(addressFieldsTranslations.lastName)"
        :model-value="lastName"
        name="lastName"
        :style="allFieldsData.lastName.style"
        :tabindex="allFieldsData.lastName.index"
        @input="
          emit('update:lastName', ($event.target as HTMLInputElement).value)
        "
      />
    </div>

    <div
      v-if="shouldDisplayPronunciationFields"
      class="grid gap-16 md:flex md:flex-wrap"
      :style="allFieldsData.namePronunciation.style"
    >
      <RevInputText
        id="firstNamePronunciation"
        class="md:min-w-[20rem] md:flex-1"
        data-cs-mask
        :error="errors.firstNamePronunciation"
        :label="i18n(addressFieldsTranslations.firstNamePronunciation)"
        :model-value="firstNamePronunciation"
        name="firstNamePronunciation"
        :style="allFieldsData.firstNamePronunciation.style"
        :tabindex="allFieldsData.firstNamePronunciation.index"
        @input="
          emit(
            'update:firstNamePronunciation',
            ($event.target as HTMLInputElement).value,
          )
        "
      />

      <RevInputText
        id="lastNamePronunciation"
        class="md:min-w-[20rem] md:flex-1"
        data-cs-mask
        :error="errors.lastNamePronunciation"
        :label="i18n(addressFieldsTranslations.lastNamePronunciation)"
        :model-value="lastNamePronunciation"
        name="lastNamePronunciation"
        :style="allFieldsData.lastNamePronunciation.style"
        :tabindex="allFieldsData.lastNamePronunciation.index"
        @input="
          emit(
            'update:lastNamePronunciation',
            ($event.target as HTMLInputElement).value,
          )
        "
      />
    </div>

    <AddressOptionalField
      v-if="fields.company"
      button-id="show-company"
      :displayed="shouldDisplayOptionalField('company')"
      :label="i18n(addressFieldsTranslations.companyNameHidden)"
      :style="allFieldsData.company.style"
      :tabindex="allFieldsData.company.index"
      :value="company"
    >
      <RevInputText
        id="company"
        data-cs-mask
        :error="errors.company"
        :label="companyFieldLabel"
        :model-value="company"
        name="company"
        :tabindex="allFieldsData.company.index"
        @input="
          emit('update:company', ($event.target as HTMLInputElement).value)
        "
      />
    </AddressOptionalField>

    <RevInputText
      v-if="fields.publicCompanyName"
      id="publicCompanyName"
      data-cs-mask
      :description="i18n(addressFieldsTranslations.publicCompanyNameInfoText)"
      :error="errors.publicCompanyName"
      :label="i18n(addressFieldsTranslations.publicCompanyName)"
      :model-value="publicCompanyName"
      name="publicCompanyName"
      :style="allFieldsData.publicCompanyName.style"
      :tabindex="allFieldsData.publicCompanyName.index"
      @input="
        emit(
          'update:publicCompanyName',
          ($event.target as HTMLInputElement).value,
        )
      "
    />

    <div v-if="fields.country" :style="allFieldsData.country.style">
      <RevInputSelect
        id="country"
        v-model="currentCountry"
        data-cs-mask
        :error="errors.country"
        :label="i18n(addressFieldsTranslations.country)"
        name="country"
        :options="countryOptions"
        :tabindex="allFieldsData.country.index"
      />
    </div>

    <div
      v-if="displayNonJapanStates"
      :style="allFieldsData.stateOrProvince.style"
    >
      <RevInputSelect
        id="stateOrProvince"
        v-model="currentStateOrProvince"
        data-cs-mask
        :error="errors.stateOrProvince"
        :label="i18n(addressFieldsTranslations.stateOrProvince)"
        name="stateOrProvince"
        :options="states"
        :tabindex="allFieldsData.stateOrProvince.index"
      />
    </div>

    <div
      v-if="fields.street && allFieldsData.streetAutocomplete.shouldDisplay"
      :style="allFieldsData.streetAutocomplete.style"
    >
      <AddressAutocomplete
        id="street"
        v-model="searchQuery"
        autocomplete="off"
        :autocomplete-max-items="props.autocompleteMaxItems"
        class="isolation-auto"
        :countries="[currentCountry]"
        :country="currentCountry"
        data-cs-mask
        data-test="streetAutocomplete"
        :error="errors.street"
        :feature-code
        :label="i18n(addressFieldsTranslations.street)"
        name="street"
        :tabindex="allFieldsData.streetAutocomplete.index"
        type="text"
        @clear="emit('update:street', '')"
        @input="
          emit('update:street', ($event.target as HTMLInputElement).value)
        "
        @select-item="handleSearchSelectItem"
      />
    </div>

    <RevInputText
      v-if="fields.street && allFieldsData.street.shouldDisplay"
      id="street"
      data-cs-mask
      :error="errors.street"
      :label="i18n(addressFieldsTranslations.street)"
      :model-value="street"
      name="street"
      :style="allFieldsData.street.style"
      :tabindex="allFieldsData.street.index"
      @input="emit('update:street', ($event.target as HTMLInputElement).value)"
    />

    <AddressOptionalField
      v-if="fields.street2"
      button-id="show-street2"
      :displayed="shouldDisplayOptionalField('street2')"
      :label="i18n(addressFieldsTranslations.street2Hidden)"
      :style="allFieldsData.street2.style"
      :tabindex="allFieldsData.street2.index"
      :value="street2"
    >
      <RevInputText
        id="street2"
        data-cs-mask
        :error="errors.street2"
        :label="i18n(addressFieldsTranslations.street2)"
        :model-value="street2"
        name="street2"
        :tabindex="allFieldsData.street2.index"
        @input="
          emit('update:street2', ($event.target as HTMLInputElement).value)
        "
      />
    </AddressOptionalField>

    <div
      class="grid gap-16 md:flex md:flex-wrap"
      :style="allFieldsData.postalCode.style"
    >
      <AddressAutocomplete
        v-if="
          fields.postalCode &&
          allFieldsData.postalCodeAutocomplete.shouldDisplay
        "
        id="postalCode"
        v-model="searchQuery"
        autocomplete="off"
        class="md:min-w-[20rem] md:flex-1"
        :countries="[currentCountry]"
        :country="currentCountry"
        data-cs-mask
        data-test="postalCodeAutocomplete"
        :error="errors.postalCode"
        :feature-code
        :label="i18n(addressFieldsTranslations.postalCode)"
        name="postalCode"
        :tabindex="allFieldsData.postalCode.index"
        type="text"
        @clear="emit('update:postalCode', '')"
        @input="
          emit('update:postalCode', ($event.target as HTMLInputElement).value)
        "
        @select-item="handleSearchSelectItem"
      />

      <RevInputText
        v-if="fields.postalCode && allFieldsData.postalCode.shouldDisplay"
        id="postalCode"
        class="md:min-w-[20rem] md:flex-1"
        data-cs-mask
        :error="errors.postalCode"
        :label="i18n(addressFieldsTranslations.postalCode)"
        :model-value="postalCode"
        name="postalCode"
        :tabindex="allFieldsData.postalCode.index"
        @input="
          emit('update:postalCode', ($event.target as HTMLInputElement).value)
        "
      />

      <div v-if="displayJapanStates" class="md:min-w-[20rem] md:flex-1">
        <RevInputSelect
          id="stateOrProvince"
          v-model="currentStateOrProvince"
          data-cs-mask
          :error="errors.stateOrProvince"
          :label="i18n(addressFieldsTranslations.stateOrProvince)"
          name="stateOrProvince"
          :options="states"
          :tabindex="allFieldsData.stateOrProvince.index"
        />
      </div>

      <RevInputText
        v-if="fields.city && !isCountryJapan"
        id="city"
        class="md:min-w-[20rem] md:flex-1"
        data-cs-mask
        :error="errors.city"
        :label="i18n(addressFieldsTranslations.city)"
        :model-value="city"
        name="city"
        :tabindex="allFieldsData.city.index"
        @input="emit('update:city', ($event.target as HTMLInputElement).value)"
      />
    </div>

    <RevInputText
      v-if="fields.city && isCountryJapan"
      id="city"
      data-cs-mask
      :error="errors.city"
      :label="i18n(addressFieldsTranslations.city)"
      :model-value="city"
      name="city"
      :style="allFieldsData.city.style"
      :tabindex="allFieldsData.city.index"
      @input="emit('update:city', ($event.target as HTMLInputElement).value)"
    />

    <div v-if="fields.phone" :style="allFieldsData.phone.style">
      <InputAddressPhone
        id="phone"
        v-model="currentPhone"
        class="isolation-auto"
        :default-country="defaultPhoneCode"
        :description="phoneHelpMessage"
        :error="errors.phone"
        name="phone"
        :tabindex="allFieldsData.phone.index"
      />
    </div>

    <div v-if="fields.legal">
      <RevCheckbox
        id="legal"
        :checked="legal"
        name="legal"
        @change="emit('update:legal', $event.target.checked)"
      >
        <template #label>{{ i18n(addressFieldsTranslations.legal) }}</template>
      </RevCheckbox>

      <p v-if="errors.legal" class="text-static-danger-hi body-2 mt-3">
        {{ errors.legal }}
      </p>
    </div>
  </div>
</template>

<script setup lang="ts">
import { type Ref, computed, ref, watch } from 'vue'

import { Country } from '@backmarket/http-api'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { RevCheckbox } from '@ds/components/Checkbox'
import {
  type Option,
  RevInputSelect,
  type Value,
} from '@ds/components/InputSelect'
import { RevInputText } from '@ds/components/InputText'

import addressFieldsTranslations from '../../translations/addressFields.translations'
import { type BmPlacesDetails } from '../../types/bmPlaces'
import { FeatureCode } from '../../types/featureCode'
import {
  Fields,
  type FieldsNameOrder,
  type FieldsNameStyle,
} from '../../types/fieldName'
import { getFieldStyle } from '../../utils/rules/getFieldStyle'
import { getFieldTabIndex } from '../../utils/rules/getFieldTabIndex'
import { getAllStates } from '../../utils/state/getAllStates'
import { getShippingStates } from '../../utils/state/getShippingStates'
import AddressAutocomplete from '../AddressAutocomplete/AddressAutocomplete.vue'

import AddressOptionalField from './AddressOptionalField/AddressOptionalField.vue'
import InputAddressPhone from './InputAddressPhone/InputAddressPhone.vue'

interface Errors {
  city?: string
  company?: string
  country?: string
  firstName?: string
  firstNamePronunciation?: string
  lastName?: string
  lastNamePronunciation?: string
  legal?: string
  phone?: string
  postalCode?: string
  publicCompanyName?: string
  stateOrProvince?: string
  street?: string
  street2?: string
}

type OptionalFields = 'company' | 'street2'

type AddressFieldsProps = {
  autocompleteMaxItems?: number
  city?: string
  company?: string
  companyFieldLabelMessage?: string
  countries?: Option[]
  country?: Country | ''
  defaultCountry?: Country
  errors: Errors
  featureCode: FeatureCode
  firstName?: string
  firstNamePronunciation?: string
  lastName?: string
  lastNamePronunciation?: string
  legal?: boolean
  phone?: string
  phoneHelpMessage?: string
  postalCode?: string
  publicCompanyName?: string
  shippingState?: boolean
  stateOrProvince?: string
  street?: string
  street2?: string
  optionalFields?: OptionalFields[]
}

const props = withDefaults(defineProps<AddressFieldsProps>(), {
  city: undefined,
  company: undefined,
  companyFieldLabelMessage: '',
  countries: () => [],
  country: undefined,
  defaultCountry: Country.US,
  firstName: undefined,
  firstNamePronunciation: undefined,
  lastName: undefined,
  lastNamePronunciation: undefined,
  legal: undefined,
  phone: undefined,
  phoneHelpMessage: '',
  postalCode: undefined,
  publicCompanyName: undefined,
  shippingState: false,
  stateOrProvince: undefined,
  street: undefined,
  street2: undefined,
  optionalFields: () => ['company', 'street2'],
  autocompleteMaxItems: 5,
})

const emit = defineEmits([
  'autocompleted',
  'update:city',
  'update:company',
  'update:country',
  'update:firstName',
  'update:firstNamePronunciation',
  'update:lastName',
  'update:lastNamePronunciation',
  'update:legal',
  'update:phone',
  'update:postalCode',
  'update:publicCompanyName',
  'update:stateOrProvince',
  'update:street',
  'update:street2',
])

const i18n = useI18n()

const currentCountry: Ref<Country> = ref(
  Object.values(Country).some((country) => country === props.country)
    ? (props.country as Country)
    : props.defaultCountry,
)

const currentPhone: Ref<string> = ref(props.phone || '')
const currentStateOrProvince: Ref<Value> = ref(props.stateOrProvince as Value)

const searchQuery: Ref<string | undefined> = ref(
  props.country === Country.JP ? props.postalCode : props.street,
)

const containerRef = ref<HTMLElement>()

const allFieldsData = computed(() => {
  return Object.keys(Fields).reduce(
    (acc, key) => {
      const field = getFieldStyle({
        country: currentCountry.value,
        fieldName: key as keyof FieldsNameStyle,
      })

      const index = getFieldTabIndex({
        country: currentCountry.value,
        fieldName: key as keyof FieldsNameOrder,
      })

      return {
        ...acc,
        [key]: {
          style: { order: field.order },
          shouldDisplay: field.shouldDisplay ?? false,
          index,
        },
      }
    },
    {
      city: { style: {}, shouldDisplay: false, index: 0 },
      company: { style: {}, shouldDisplay: false, index: 0 },
      country: { style: {}, shouldDisplay: false, index: 0 },
      firstName: { style: {}, shouldDisplay: false, index: 0 },
      firstNamePronunciation: { style: {}, shouldDisplay: false, index: 0 },
      lastName: { style: {}, shouldDisplay: false, index: 0 },
      lastNamePronunciation: { style: {}, shouldDisplay: false, index: 0 },
      name: { style: {}, shouldDisplay: false, index: 0 },
      namePronunciation: { style: {}, shouldDisplay: false, index: 0 },
      nationalId: { style: {}, shouldDisplay: false, index: 0 },
      phone: { style: {}, shouldDisplay: false, index: 0 },
      postalCode: { style: {}, shouldDisplay: false, index: 0 },
      postalCodeAutocomplete: { style: {}, shouldDisplay: false, index: 0 },
      publicCompanyName: { style: {}, shouldDisplay: false, index: 0 },
      stateOrProvince: { style: {}, shouldDisplay: false, index: 0 },
      street: { style: {}, shouldDisplay: false, index: 0 },
      street2: { style: {}, shouldDisplay: false, index: 0 },
      streetAutocomplete: { style: {}, shouldDisplay: false, index: 0 },
    },
  )
})

const fields = computed(() => ({
  city: props.city !== undefined,
  company: props.company !== undefined,
  country: props.country !== undefined,
  firstName: props.firstName !== undefined,
  firstNamePronunciation: props.firstNamePronunciation !== undefined,
  lastName: props.lastName !== undefined,
  lastNamePronunciation: props.lastNamePronunciation !== undefined,
  legal: props.legal !== undefined,
  phone: props.phone !== undefined,
  postalCode: props.postalCode !== undefined,
  publicCompanyName: props.publicCompanyName !== undefined,
  street: props.street !== undefined,
  street2: props.street2 !== undefined,
}))

const companyFieldLabel = computed(() =>
  isEmpty(props.companyFieldLabelMessage)
    ? i18n(addressFieldsTranslations.companyOptional)
    : props.companyFieldLabelMessage,
)

const defaultPhoneCode = computed(() =>
  props.defaultCountry ? props.defaultCountry : Country.US,
)

const countryOptions = computed((): Option[] => {
  if (!isEmpty(props.countries)) return props.countries

  const translatedCountries = Object.values(Country).map((country) => ({
    label: i18n.country(country),
    value: country,
  }))

  return translatedCountries.sort((a, b) => a.label.localeCompare(b.label))
})

const states = computed(() => {
  const getStates = props.shippingState ? getShippingStates : getAllStates

  return isEmpty(currentCountry.value) ? [] : getStates(currentCountry.value)
})

const hasStates = computed(() => {
  return !isEmpty(states.value)
})

const isCountryJapan = computed(() => props.country === Country.JP)

const displayNonJapanStates = computed(
  () => props.country !== undefined && hasStates.value && !isCountryJapan.value,
)

const displayJapanStates = computed(
  () => props.country !== undefined && hasStates.value && isCountryJapan.value,
)

const shouldDisplayPronunciationFields = computed(
  () =>
    props.firstNamePronunciation !== undefined ||
    props.lastNamePronunciation !== undefined,
)

const shouldDisplayOptionalField = (fieldName: OptionalFields) => {
  return !props.optionalFields.includes(fieldName)
}

watch(
  currentCountry,
  async (newCountry) => {
    emit('update:country', newCountry)
    if (!hasStates.value) {
      currentStateOrProvince.value = ''
    }
  },
  {
    immediate: true,
  },
)

watch(currentStateOrProvince, async (newStateOrProvince) => {
  emit('update:stateOrProvince', newStateOrProvince)
})

watch(currentPhone, async (newPhone) => {
  emit('update:phone', newPhone)
})

watch(
  () => props.errors,
  (errors) => {
    if (!containerRef.value && !isEmpty(errors)) {
      return
    }

    containerRef.value
      ?.querySelector(`#${Object.keys(errors)[0]}`)
      ?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      })
  },
)

function handleSearchSelectItem(address: BmPlacesDetails, index: number) {
  if (hasStates.value) {
    const state =
      Object.values(states.value).find(
        ({ label }) => label === address.stateProvince,
      )?.value || ''

    currentStateOrProvince.value = state
  }

  if (address.country === Country.JP) {
    const postalCode = address.postalCode || ''
    if (postalCode.length > 3) {
      searchQuery.value = `${postalCode.substring(0, 3)}-${postalCode
        .replace('-', '')
        .substring(3)}`
    } else {
      searchQuery.value = address.postalCode || ''
    }
  } else {
    searchQuery.value = address.street || ''
  }

  emit('update:city', address.city)
  emit('update:country', address.country)
  emit('update:postalCode', address.postalCode)
  emit('update:street', address.street)
  emit('update:street2', address.street2)
  emit('autocompleted', address, index)
}
</script>
